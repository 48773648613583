import {useEffect, useState} from 'react';
import axios from 'axios';

interface Mountain {
    url: string;
    name: string;
    elevation: string;
    photourl: string;
    latitude: number;
    longitude: number;
}

interface MoutainImageProps {
    url: string;
    counter:number;
}

export const MountainImage = (props: MoutainImageProps) => {
    const [maxwidth, setmaxwidth] = useState<number>(window.innerWidth);
    const [earthLink, setEarthLink] = useState<string>('');
    const [mapsLink, setMapsLink] = useState<string>('');
    const [mountain, setMountain] = useState<Mountain>({
        name: '',
        url: '',
        elevation: '',
        photourl: '',
        latitude: 0,
        longitude: 0
    });

    useEffect(() => {   
        const fetchMountain = async () => {
            const resp = await axios.get(props.url);
            setMountain(resp.data);
            setEarthLink('https://earth.google.com/web/@' + resp.data.latitude + ','+ resp.data.longitude + ',4218.99710871a,40080.43145136d,1y,-0h,0t,0r')
            setMapsLink('https://maps.google.com/?q=' + resp.data.latitude + ','+ resp.data.longitude)
        };

        fetchMountain().catch(console.error);

        const setImageSize = () => {
            if (window.innerWidth > 1000) { 
                setmaxwidth(800);
            }else{
                setmaxwidth ( .8 * window.innerWidth)
            }
        };
        const handleResize = () => {
            setImageSize();
        }
        window.addEventListener('resize', handleResize)
        setImageSize();

    }, [props.counter, props.url]);


    return (
        <div className='mountain-container'>
            <img src={mountain.photourl} alt={mountain.name} style={{ maxWidth: maxwidth }}/>
            <p>{mountain.name.toUpperCase()}</p>
            <p>{mountain.elevation}</p>
            <a className='link' href={mountain.url}>Wikipedia</a>
            <a className='link' href={earthLink}>Google Earth</a>
            <a className='link' href={mapsLink}>Google Maps</a>
        </div>
    );
};
