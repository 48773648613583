import {useCallback} from 'react';
import { MountainImage } from "./MountainImage";

interface NavbarProps {
  setMountainImage: React.Dispatch<React.SetStateAction<JSX.Element>>;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  counter: number;
}

export const Navbar = (props: NavbarProps) => {

  const routeChangeRandom = useCallback(() => {
    props.setCounter(props.counter + 1);
    props.setMountainImage(<MountainImage url="/api/random" counter={props.counter}/>);
  }, [props.counter, props.setMountainImage, props.setCounter]);

  const routeChangeHome = useCallback(() => {
    props.setCounter(props.counter + 1);
    props.setMountainImage(<MountainImage url="/api/daily" counter={props.counter}/>);
  }, [props.counter, props.setMountainImage, props.setCounter]);

  return (
    <div className='navbar'>
      <ul>
        <li><button className='navbar__button' onClick={routeChangeHome}>Mountainoftheday.com</button></li>
        <li><button className='navbar__button__random' onClick={routeChangeRandom}>Random</button></li>
      </ul>
    </div>
  );
}