import {useEffect, useState} from 'react';
import "./App.css";
import { MountainImage } from "./components/MountainImage";
import { Navbar } from "./components/Navbar";

function App() {
  const [counter, setCounter] = useState(0);
  const [mountainImageJSX, setMountainImageJSX] = useState<JSX.Element>(<MountainImage url="/api/daily" counter={counter}/>);

  return (
    <div className="app">
      <Navbar setMountainImage={setMountainImageJSX} setCounter={setCounter} counter={counter} />
      {mountainImageJSX}
    </div>
  );
}

export default App;
